import "core-js/stable";
import "regenerator-runtime/runtime";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from 'element-plus'
import VueWechatTitle from "vue-wechat-title";
import store from "./store";
import "./utils/rem";
import "./utils/af";
import './assets/css/font.css'

// Toast
import {
  Toast,
  Dialog,
  List,
  NumberKeyboard,
  Swipe,
  SwipeItem,
  Switch,
  Tab,
  Tabs,
  Progress,
  Button,
  Field,
  Icon,
  Uploader,
  Rate,
  DropdownMenu,
  DropdownItem
} from "vant";
import "vant/lib/index.css";
// import VConsole from "vconsole";
// new VConsole();

const app = createApp(App);
app
  .use(router)
  .use(VueWechatTitle)
  .use(store)
  .use(List)
  .use(NumberKeyboard)
  .use(Swipe)
  .use(ElementPlus)
  .use(SwipeItem)
  .use(Switch)
  .use(Tab)
  .use(Tabs)
  .use(Progress)
  .use(Button)
  .use(Field)
  .use(Icon)
  .use(Toast)
  .use(Dialog)
  .use(Uploader)
  .use(Rate)
  .use(DropdownMenu)
  .use(DropdownItem)
  .mount("#app");
